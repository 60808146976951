<template>
  <div>
    <el-form
      label-position="right"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="60px"
      style="text-align:left"
    >
      <el-col :span="12">
        <el-form-item label="source" label-width="110px" prop="source">
          <el-input v-model="ruleForm.source" style="width: 100%" placeholder="请输入source"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="outerSource" label-width="110px" prop="outerSource">
          <el-input v-model="ruleForm.outerSource" placeholder="请输入outerSource"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="cid" label-width="110px" prop="cid">
          <el-input v-model="ruleForm.cid" style="width: 100%" placeholder="请输入cid"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="outerId" label-width="110px" prop="outerId">
          <el-input v-model="ruleForm.outerId" placeholder="请输入outerId"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="umCode" label-width="110px" prop="umCode">
          <el-input v-model="ruleForm.umCode" style="width: 100%" placeholder="请输入umCode"></el-input>
        </el-form-item>
      </el-col>
    </el-form>
    <el-button style="float: right;" type="primary" @click="onSubmit('ruleForm')">保存</el-button>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const customerEdit = api()('enterprise.config.edit.json');

export default {
  props: {
    code: String,
    userInfo: Object
  },
  data () {
    return {
      ruleForm: {
        source: '',
        outerSource: '',
        cid: '',
        outerId: '',
        umCode: '',
      },
      rules: {

      },
    }
  },
  mounted () {
    Object.keys(this.ruleForm).forEach(it => {
      this.ruleForm[it] = this.userInfo[it]

    })
    console.log(this.userInfo);
  },
  methods: {
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          customerEdit({
            ...this.ruleForm,
            code: this.code
          }
          ).then(() => {

            this.$message({
              message: '保存成功',
              type: 'success'
            });
            // window.history.back(-1);
          });
        } else {
          return false;
        }
      });
    }
  },
}
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>